<template>
  <div class="favorite">
    <!-- 头部 -->
    <van-nav-bar
      title="收藏夹"
      left-arrow
      @click-left="onClickLeft"
      fixed
    />
    <!-- 列表 竖版 -->
    <div class="list_content" v-if="goods.length > 0 ? true : false">
      <van-list
        v-model="goodsloading"
        :finished="goodsfinished"
        finished-text="没有更多了"
        @load="getGoodsList"
        :immediate-check="false"
      >
        <van-grid :gutter="10" :column-num="2" :border="false">
          <van-grid-item v-for="(item,index) in goods" :key="item.goodsid" :data-index="index" :data-id="item.goodsid" @click="details(item.goodsid)">
            <template #icon>
              <van-image
                :src="item.thumb"
                :style="'width:' + (screenWidth - 30) / 2 + 'px;height:' + (screenWidth - 30) / 2 + 'px;'"
                lazy-load
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="shuiyin">{{strId}}</div>
            </template>
            <template #text>
              <div class="goods_text van-multi-ellipsis--l2" :style="'width:'+((screenWidth-30)/2-20)+'px;'">{{item.title}}</div>
              <div class="goods_price van-ellipsis" :style="'width:'+((screenWidth-30)/2-20)+'px;'">
                <span style="color: #C8241A;">&yen;</span> {{item.show_price}}
                <span v-show="false && parseInt(item.sales) > 0 ? true : false">{{item.sales}}人已购买</span>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </van-list>
    </div>
    <!-- 空状态 -->
    <div class="list_content" v-if="nodata">
      <van-empty description="空空如也" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Favorite',
  data(){
    return {
      page: 1, // 页码
      goodsloading: false, // 是否加载下一批数据
      goodsfinished: false, // 是否加载完毕
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      uid: localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "", // 用户信息
      islogin: localStorage.getItem("DK_ISLOGIN") ? localStorage.getItem("DK_ISLOGIN") : false, // 是否登录
      goods: [], // 产品列表
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      nodata: false, // 空数据
    }
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    
  },
  activated() {
    this.$parent.getmerchname(this.merchid, "收藏夹");
    this.init();
  },
  methods: {
    init() {
      this.getmid();
    },
    getmid() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + 'web/user/get_memberid')
        .then(res => {
          if (res.data.code == 100000) {
            _that.mid = res.data.data;
            this.getSearch();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取产品数据
    getSearch() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + 'web/user/favorite',
          _that.$qs.stringify({
            page: _that.page,
            mid: _that.mid,
            merchid: _that.merchid
          })
        )
        .then(function(response){
          if (response && response.data.code == 100000) {
            if (response.data.data.length > 0) {
              _that.goods = response.data.data;
              _that.page ++;
            }
          }else {
            _that.goodsfinished = true; // 是否加载完毕
            _that.nodata = true;
          }
        })
        .catch(function(error){
          console.log(error);
        })
    },
    // list 加载获取产品数据
    getGoodsList() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + 'web/user/favorite',
          _that.$qs.stringify({
            page: _that.page,
            mid: _that.mid,
            merchid: _that.merchid
          })
        )
        .then(function(response) {
          _that.goodsloading = false; // 是否加载下一批数据
          if (response && response.data.code == 100000) {
            if (response.data.data.length <= 0) {
              _that.goodsfinished = true; // 是否加载完毕
            }else{
              response.data.data.forEach((item,index)=> {
                _that.goods.push(item);
              })
              _that.page ++;
            }
          }else{
            _that.goodsfinished = true; // 是否加载完毕
          }
        })
        .catch(function(error) {
          console.log(error);
        })
    },
    // 点击左侧按钮
    onClickLeft() {
      this.$router.go(-1);
    },
    // 进入详情页
    details(id) {
      this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    }
  }
}
</script>
<style lang="less">
.favorite{
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: #ededed;
  padding-top: 50px;
  // 头部
  .van-nav-bar .van-icon{
    color: #333;
  }
  // 列表
  .list_content,
  .list_twocontent{
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
  }
  // 双排竖版
  .list_content{
    .van-grid-item__content{
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      .van-image{
        display: block;
      }
      .goods_text{
        height: 40px;
        line-height: 20px;
        padding: 5px 10px 0;
        font-size: 14px;
        color: #333;
        text-align: left;
      }
      .goods_price{
        padding: 5px 10px 10px;
        font-size: 16px;
        color: #C8241A;
        text-align: left;
        span{
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  // 水印
  .shuiyin{
    padding: 2px;
    background-color:#fff;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
  }
}
</style>
